// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-experience-page-js": () => import("/opt/build/repo/src/templates/ExperiencePage.js" /* webpackChunkName: "component---src-templates-experience-page-js" */),
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/IndexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-projects-page-js": () => import("/opt/build/repo/src/templates/ProjectsPage.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

